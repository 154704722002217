// Librerias
import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
// Exportando Variables
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import HouseContextProvider from './comp/portafolio/project/HouseContext';

//
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
//  <HouseContextProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </React.StrictMode>
//  </HouseContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
