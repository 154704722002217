import React from 'react'
import { Link } from 'react-router-dom'

export function Page404 () {
    return (
        <div className='flex-1 pt-40 text-center lg:h-[700px] bg-site bg-no-repeat bg-cover overflow-hidde'>
            <h1> Pagina no encontrada!</h1>
            <h3> Esta URL no es correcta D:</h3>
            <button className='btn btn-lg mt-5'><Link to="/"> volver a INICIO</Link></button>
        </div>
    )
}
