import React from 'react';

//
import background from "../assets/mecanico.jpg";

export function MantenimientoMecanico () {
    
    return (
        <section className='section bg-cover bg-center' id='mantenimientomecanico' style={{ backgroundImage: `url(${background})` }}>
        
    {/* *****************| NOTEBOOK Y PC|********************** */}
            <div className='container mx-auto'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>MANTENIMIENTO <br/> MECANICO</h1>
                    <h2 className='bg-black p-5'>
                        El área de mantención mecánica se orienta en organizar adecuadamente el servicio 
                        de mantenimiento en las industrias a través de la definición de diversos programas, 
                        ya sean preventivos o correctivos, donde cuyo principal objetivo es optimizar la
                        disponibilidad, continuidad y seguridad de los equipos e instalaciones de nuestros
                        clientes.<br/><br/>
                        <div className='hidden md:grid grid-cols-2'>
                            <div className=''>
                                -Molinos de carbón.<br/>
                                – Turbinas de Vapor y gas<br/>
                                – Alimentadores de carbón.<br/>
                                – Sistema de escoria.<br/>
                                – Sistema de ceniza.<br/>
                                – Sistema de vapor caldera.<br/>
                                – Mantención Quemadores de partida.<br/>
                            </div>
                            <div className=''>
                                – Mantención Dámper.<br/>
                                – Mantencion ventiladores (VAP, VAS, VAT).<br/>
                                – Plantas desaladoras.<br/>
                                – Intercambiadores de calor.<br/>
                                – Moto reductores.<br/>
                                – Válvulas<br/>
                                – Chancadores de martillos, muelas.<br/>
                            </div>
                        </div>

    {/* *****************| MOBILE|********************** */}
                        <div className='md:hidden grid grid-cols-1'>
                            <div className=''>
                                -Molinos de carbón.<br/>
                                – Turbinas de Vapor y gas<br/>
                                – Alimentadores de carbón.<br/>
                                – Sistema de escoria.<br/>
                                – Sistema de ceniza.<br/>
                                – Sistema de vapor caldera.<br/>
                                – Mantención Quemadores de partida.<br/>
                                – Mantención Dámper.<br/>
                                – Mantencion ventiladores (VAP, VAS, VAT).<br/>
                                – Plantas desaladoras.<br/>
                                – Intercambiadores de calor.<br/>
                                – Moto reductores.<br/>
                                – Válvulas<br/>
                                – Chancadores de martillos, muelas.<br/>
                            </div>
                        </div>
                    </h2>
                    <div className='grid grid-cols-2 bg-black text-center '>
                    </div>                   
                    
                </div>

                
            </div>
        </section>
    )
}
