//LIBRERIAS
import { createContext, useContext, useEffect, useState } from "react";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail} from 'firebase/auth'
// IMPORTANDO VARIABLES
import {auth} from "../firebase/firebase.config";

// FUNCIONES DE AUTENTIFICACION
export const authContext = createContext(); 

//
export const useAuth = () => {
    const context = useContext(authContext);
    if (!context) throw new Error('There is not proviver');
    return context;
};

// FUNCION PARA REGISTRAR
export function AuthProvider ({children}) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const signup = (email, password) =>  
    createUserWithEmailAndPassword(auth, email, password);


// FUNCION PARA INGRESAR
const login = async(email, password) =>{ 
    const userCredentials = await signInWithEmailAndPassword(auth, email, password);
    console.log(userCredentials);
}

//
const resetPass = (email) => {
    sendPasswordResetEmail(email)
}

// FUNCION PARA ALMACENAR LA INFO DEL USUARIO
useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
        setUser(currentUser);
        setLoading(false)
    })
}, [])

// FUNCION PARA DESLOGEARSE
const logout = () => signOut(auth)

// FUNCION PARA INGRESAR CON GOOGLE
const loginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider()
    return (signInWithPopup(auth, googleProvider));
}

    // retorno visual
    return (
        <authContext.Provider value={{ signup, login, user, logout, loading, loginWithGoogle, resetPass }}> {children} </authContext.Provider>
    );
}