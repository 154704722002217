import React from 'react'

//
import background from "../assets/soldadura2.jpg";

export function SoldaduraEspecial() {
    return (
        <section className='section bg-cover' id='soldaduraespecial' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>SOLDADURA <br/> ESPECIAL Y GENERAL</h1>
                    <h2 className='bg-black p-5'>
                        especializando en el montaje cañerías y ductos, en donde realizamos la fabricación
                        y montaje de Spool, en taller y terreno, adecuándonos siempre a las necesidades
                        de cada cliente, proyecto y especicaciones técnicas de los mismos. Contamos
                        con las competencias adecuadas para cumplir las exigencias en los distintos
                        tipos soldadura eléctrica tales como: Arco Manual, MIG, TIG, Electro 
                        Fusión (HDPE). Además dominamos los sistemas de acoplamiento mecánico o 
                        manual, tales como los sistemas Roscados (NPT, BSP), apernados con 
                        anges, adhesivos etc.<br/>

                        ACEROS
                        - Aplicación y reparación de soldaduras;<br/> 
                        – Sistema de soldadura.<br/> 
                        – Proceso MIG<br/> 
                        – Proceso TIG<br/> 
                        – Proceso SMAW<br/> 
                        – Proceso GMAW<br/> 
                        – Proceso arco sumergido<br/> 
                        – Proceso GTAW<br/> 

                    </h2>
                </div>
            </div>
        </section>
    )
}
