import React from 'react'

import background from "../assets/servicios-generales.png";


export default function ServiciosGenerales() {
  return (
    <section className='section w-full bg-cover' id='serviciosgenerales' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto mb-10'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>Servicios<br/> Generales  <br/></h1>
                    <h2 className='bg-black p-5'><br/>
                      Nosotros sabemos que nuestros clientes deben mantener su foco estratégico en las labores directas de cada negocio, por lo que nuestros servicios generales están orientados a solucionar las diversas necesidades de mantención de las distintas instalaciones existentes.
                      Dentro de nuestros servicios podemos destacar:<br/><br/>
                      • Gasfitería.<br/>
                      • Electricidad.<br/>
                      • Pintura.<br/>
                      • Demarcación vial.<br/>
                      • Entre otros.<br/>

                 </h2>
                </div>
            </div>
        </section>
  )
}
