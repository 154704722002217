import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

//
import { Header } from '../comp/01-Header'
import background1 from "../assets/NOSOTROS.jpg";
import background2 from "../assets/MISION.svg";
import background3 from "../assets/VISION.jpg";
import background4 from "../assets/mision.jpg";
import { Footer } from '../comp/11-Footer';

export default function Nosotros() {
  return (
    <section className='bg-center text-black'>
        <FloatingWhatsApp  
            phoneNumber="+56988190843" 
            darkMode="true"
            chatMessage="Hola Buenos dias, En que podemos ayudarte?"
            accountName="EMIZA SPA"
            />
    <Header className/>
    {/******************||*****************************/}
    <div className='text-white bg-no-repeat bg-cover overflow-hidden'  > 
        <div className=' lg:grid lg:grid-cols-2 text-center' > 
            
    {/******************|NOSOTROS|*****************************/}
                {/**|NOSOTROS TEXTO|**/}
            <div className=' text-lg pt-5 p-5 lg:pt-10 lg:pl-10  uppercase bg-black  font-extralight' >
                <h1 className='text-5xl lg:text-8xl my-5 lg:mt-10 lg:mb-10 font-extrabold  text-center'>
                    NOSOTROS
                </h1>
                    Nuestra empresa nace un 16 de junio del año 2020 con el nombre de Emiza servicios 
                    integrales spa, en la ciudad de Tocopilla, Antofagasta, Chile. Nuestras gestiones
                    se enfocan en brindar servicios en mecánica general, obras civiles, soldaduras. 
                    Mantenimiento eléctrico general, montajes y aseo industrial.            
            </div>

                {/**|NOSOTROS PC Y NOTEBOOK|**/}
            <div className='hidden md:block font-extralight lg:pt-10 lg:pl-10 bg-center' style={{ backgroundImage: `url(${background1})` }}>
                .            
            </div>

                {/**|NOSOTROS MOBILE|**/}
            <div className='flex h-[400px] md:hidden font-extralight lg:pt-10 lg:pl-10 bg-center bg-cover' style={{ backgroundImage: `url(${background1})` }}>
                .            
            </div>

{/******************|MISION|*****************************/}
                {/**|MISION PC Y NOTEBOOK|**/}
            <div className='hidden md:flex  lg:pt-10 lg:pl-10 font-extralight uppercase bg-cover bg-center' style={{ backgroundImage: `url(${background2})` }}>
                .            
            </div>

    {/******************|MISION|*****************************/}
                {/**|MISION TEXTO|**/}
            <div className='font-extralight pt-10 pl-10 uppercase bg-black p-5'>
                <h1 className='text-5xl lg:text-8xl my-5 lg:mt-10 lg:mb-10 font-extrabold text-center'>
                    MISIÓN
                </h1>
                Brindar servicios con el profesionalismo y calidad, con un alto nivel de eficiencia 
                que nos permita ganarnos la confianza de nuestros clientes. Es por ello por lo que 
                apuntamos a realizar nuestras funciones de manera responsable y confiable. 
                Así mismo se suma nuestro gran compromiso en trabajar bajo medidas de seguridad 
                y cuidado por nuestro entorno natural, que es posible gracias a nuestros profesionales
                que se encuentran comprometidos con nuestros valores y al pleno desarrollo
                de sus competencias.              
            </div>

                {/**|MISION MOBILE|**/}
            <div className='flex md:hidden h-[400px] font-extralight pt-10 pl-10 uppercase bg-cover bg-center' style={{ backgroundImage: `url(${background2})` }}>
                .            
            </div>

    {/******************|VISION|*****************************/}
                {/**|VISION TEXTO|**/}
            <div className='font-extralight pl-10 uppercase bg-black p-5'>
                <h1 className='text-5xl lg:text-8xl my-5 lg:mt-10 lg:mb-10 font-extrabold text-center'>
                    VISIÓN
                </h1>
                    Consolidarnos como una empresa líder en servicios Integrales industrial, 
                    reconocida por un excelente desempeño operacional y satisfacción de nuestros
                    clientes. cuya finalidad es mantener un alto estándar en servicios 
                    industriales, A través de la confianza, innovación, y adaptación. 
                    Capaz de crear lazos de confianza con sus clientes, a través de la confianza,
                    innovación, y adaptación. Basando la sustentabilidad en procesos 
                    eficientes y de altos niveles de calidad y desarrollo. además de Consolidar
                    nuestra presencia en el territorio local, regional y nacional.
            </div>
            
                {/**|VISION PC Y NOTEBOOK|**/}
            <div className=' hidden md:flex font-extralight pt-10 pl-10 uppercase bg-center bg-cover' style={{ backgroundImage: `url(${background3})` }}>
                .            
            </div>

            {/**|VISION MOBILE|**/}
            <div className='md:hidden h-[400px] font-extralight pt-10 pl-10 uppercase bg-center bg-cover' style={{ backgroundImage: `url(${background3})` }}>
                .            
            </div>
    {/******************|VALORES|*****************************/}
            {/**|VALORES PC Y NOTEBOOK|**/}
            <div className='hidden lg:flex font-extralight pt-10 pl-10 uppercase bg-black p-5 bg-cover bg-center' style={{ backgroundImage: `url(${background4})` }}>

                .
            </div>

        {/**|VALORES TEXTO|**/}
            <div className='font-extralight pt-10 pl-10 uppercase bg-black p-5'>
                <h1 className='text-5xl lg:text-8xl my-5 lg:mt-10lg:mb-10 text-center  font-extrabold '>
                    NUESTROS VALORES
                </h1><br/>
                <div className='grid grid-cols-2'>
                    <div className=''>
                        -	Cooperación<br/>
                        -	Innovación <br/>
                        -	Seguridad<br/>
                        -	Amabilidad<br/>
                        -	Confianza<br/>
                        -	Atención<br/>
                        -	Motivación<br/>
                    </div>
                    <div className=''>
                        -	Responsabilidad<br/>
                        -	Compromiso <br/>
                        -	Competitividad <br/>
                        -	- Excelencia<br/>
                        -	Ética profesional<br/>
                        -	Apertura al cambio <br/>
                    </div>
                </div>
            </div>

        {/**|VALORES MOBILE|**/}
         <div className=' h-[400px] lg:hidden font-extralight pt-10 pl-10 uppercase bg-black p-5 bg-cover bg-center' style={{ backgroundImage: `url(${background4})` }}>
                .
            </div>
        </div>
    {/******************|FOOTER|*****************************/}
        <Footer/>
        
    </div>
</section>
  )
}
