import React from 'react'

//
import background from "../assets/termoplastico.jpg";

export function Termoplastico () {
    return (
        <section className='section w-full bg-cover' id='termoplastico' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto mb-10'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>Termoplastico  <br/></h1>
                    <h2 className='bg-black p-5'><br/>
                    Aplicación, fabricación y reparación soldaduras en: tubos,cañería, geomembranas, piezas especiales.- Sistema de soldadura.
                        - Termofusión<br/>
                        - Electrofusion<br/>
                        - Extrusion<br/>
                        - Unión por cuña térmica<br/><br/>

                        – Productos termoplásticos.<br/>
                        - HDPE (polietileno de alta densidad)<br/>
                        - PVC (policloruro de vinilo)<br/>
                        - CPVC (policloruro de vinilo clorado)<br/>
                        - PP (polipropileno)<br/>
                        - PVDF (Fluoruro de polivinilideno)                    </h2>
                </div>
            </div>
        </section>
    )
}