import React from 'react';

//
import background from "../assets/montaje_industrial2.jpg";

export function  MontajeIndustrial () {
    return (
        <section className='section bg-cover bg-center' id='montajeindustrial' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>
                        MONTAJE <br/> INDUSTRIAL
                    </h1>
                    <h2 className='bg-black p-5'>
                        Ofrecemos el servicio de fabricación y montaje industrial. Este servicio 
                        se realiza con todos los estándares y criterios de medición, seguridad industrial, 
                        además de los controles de calidad correspondientes. La utilización de maquinaria y
                        equipos apropiados, personal certificado y calificado para cada actividad en la 
                        operación del montaje, lo cual garantiza una instalación rápida, segura e 
                        igualmente efectiva.
                    </h2>
                </div>
            </div>
        </section>
    )
}