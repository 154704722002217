import React from 'react'
import { Link } from 'react-router-dom';
//
import Logo from "../assets/Logo.png";

export function Header () {
    return (
        <header className='py-0 cursor-pointer text-black'>
    {/***********************|PC Y NOTEBOOK|*********************/}
            <div className='hidden md:flex container mx-auto'>
                <div className='lg:flex lg:gap-4 '>
                {/******/}
                    <Link to='/'>
                        <img src={Logo} 
                            alt='none'
                            className='w-[200px] '/>
                    </Link>

                {/******/}
                <button className='ml-[10px] lg:ml-[330px] lg:text-xl lg:hover:text-2xl hover:text-yellow-500 font-bold-'>
                    <Link to='/'>
                        INICIO
                    </Link>
                </button>
                {/******/}
                <button className='ml-[10px] lg:text-xl lg:hover:text-2xl hover:text-yellow-500 font-bold-'>
                    <Link to='/nosotros'>
                        NOSOTROS
                    </Link>
                </button>

                <button className='ml-[10px] lg:text-xl lg:hover:text-2xl lg:hover:text-yellow-500 font-bold-'>
                    <Link to='/servicios'>
                        NUESTROS SERVICIOS
                    </Link>
                </button>

                <button className='ml-[10px] lg:text-xl lg:hover:text-2xl lg:hover:text-yellow-500 font-bold-'>
                    <Link to='/contacto'>
                        CONTACTO
                    </Link>
                </button>

                {/* <button className='ml-[10px] lg:text-xl lg:hover:text-2xl lg:hover:text-yellow-500 font-bold-'>
                    <Link to='/trabajar'>
                        TRABAJA CON NOSOTROS
                    </Link>
                </button> */}

                </div>
            </div>

    {/***********************|MOBILE|*********************/}
            <div className='md:hidden container mx-auto'>
                <div className='gap-4 '>
                {/******/}
                    <Link to='/'>
                        <img src={Logo} 
                            alt='none'
                            className='w-[150px] '/>
                    </Link>

                {/******/}
                <button className='ml-[8px] text-sm hover:text-yellow-500 font-bold-'>
                    <Link to='/'>
                        INICIO
                    </Link>
                </button>
                {/******/}
                <button className='ml-[8px] text-sm hover:text-yellow-500 font-bold-'>
                    <Link to='/nosotros'>
                        NOSOTROS
                    </Link>
                </button>

                <button className='ml-[8px] text-sm hover:text-yellow-500 font-bold-'>
                    <Link to='/servicios'>
                        NUESTROS SERVICIOS
                    </Link>
                </button>

                <button className='ml-[8px] text-sm hover:text-yellow-500 font-bold-'>
                    <Link to='/contacto'>
                        CONTACTO
                    </Link>
                </button>

                {/* <button className='ml-[10px] lg:text-xl lg:hover:text-2xl lg:hover:text-yellow-500 font-bold-'>
                    <Link to='/trabajar'>
                        TRABAJA CON NOSOTROS
                    </Link>
                </button> */}

                </div>
            </div>
        </header>

        
    )
}