// LIBRERIAS
import { Routes,Route, Navigate } from "react-router-dom";
import {AuthProvider} from "./context/authContext"
import { ProtectedRoute } from "./context/ProtectedRoute";

// IMPORTANDO VARIABLES
import { Main } from "./page/Main";
import { Page404 } from "./page/Page404";
import Inicio from "./page/Inicio";
import Nosotros from "./page/Nosotros";
import Contacto from "./page/Contacto";
import TrabajaConNosotros from "./page/TrabajaConNosotros";

//
function App() {

  // RUTAS DENTRO DE LA APP
  return(
    <div className="bg-white text-black">
      <AuthProvider>
        <Routes>
            <Route path="/servicios" element={<Main />} />
            <Route path="/*" element={<Page404 />} />
            <Route path="/" element={<Inicio />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/trabajar" element={<TrabajaConNosotros />} />
        </Routes>
      </AuthProvider>
    </div>
  )
}

  export default App;