import React from 'react'
import { Header } from '../comp/01-Header'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

//
import background2 from "../assets/obras.jpg";

// import house images
import Logo1 from '../assets/Logo.jpg';
import Logo2 from '../assets/logo2.png';
import Logo3 from '../assets/Logo.jpg';
import Logo4 from '../assets/logo2.png';
import Logo5 from '../assets/Logo.jpg';
import { Footer } from '../comp/11-Footer';

export default function Inicio() {
    const LOGOS = [
        <img src={Logo1} />,
        <img src={Logo2} />,
        <img src={Logo3} />,
        <img src={Logo4} />,
        <img src={Logo5} className="h-[150px] "/>,
        
    ];

  return (
// 
    <section className='bg-center text-black'>
      <FloatingWhatsApp  
            phoneNumber="+56988190843" 
            darkMode="true"
            chatMessage="Hola Buenos dias, En que podemos ayudarte?"
            accountName="EMIZA SPA"
            />
    <Header className/>
    <div className='text-white bg-site bg-no-repeat bg-cover overflow-hidden' >
      {/******************|IMAGEN DE FONDO|*****************************/}
        <div className='h-[1000px] lg:grid lg:grid-cols-2 lg:h-[575px] text-center bg-cover  bg-center' style={{ backgroundImage: `url(${background2})` }}> 
      
      {/******************|TITULO|*****************************/}
            <div className='font-extralight pt-10 pl-10 bg-black p-5'>
                <h1 className='text-5xl lg:text-8xl lg:mt-20 font-extrabold mb-10 text-center'>SOMOS <br/>EMIZA</h1>
                UNA EMPRESA DE SERVICIOS MULTIDISCIPLINARIO DE INGENIERÍA, ORIENTADA A SATISFACER LAS NECESIDADES DE NUESTROS CLIENTES
            </div>
        </div>

{/*********|CAROUSEL LOGO PC Y NOTEBOOK|************************************************************************************/}
      <div className="hidden md:flex relative m-auto w-full overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*20)]">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[300px] items-center justify-center"
              key={index}>
              {logo}
            </div>
          ))}
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[300px] items-center justify-center"
              key={index}>
              {logo}
            </div>
          ))}
        </div>
      </div>

      {/*********|CAROUSEL LOGO MOBILE|************************************************************************************/}
      <div className="md:hidden relative m-auto w-full overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*20)]">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[150px] items-center justify-center"
              key={index}>
              {logo}
            </div>
          ))}
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[300px] items-center justify-center"
              key={index}>
              {logo}
            </div>
          ))}
        </div>
      </div>
    </div>
       {/******************|FOOTER|*****************************/}
       <Footer/>
</section>
  )
}
