import React from 'react'

//
import background from "../assets/obras.jpg";

export function ObrasCiviles () {

    return (
        <section className='section' id='obrasciviles' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>OBRAS  <br/> CIVILES</h1>
                    <h2 className='bg-black p-5'>
                    El área de obra civiles se basa en entregar el mejor servicio de construcción y 
                    montaje en área industrial, por medio del cumplimiento de estándares de calidad,
                    los tiempos de ejecución ofrecidos y seguridad.<br/><br/>
                    -  Ejecución de obras en general<br/>
                    -	Fabricación de galpones<br/>
                    -	confección de infraestructura<br/>
                    -	Movimiento de tierras<br/>
                    -	Construcción de sedes vecinales<br/>
                    -	Confección de base de hormigón H-30<br/>
                    -	Instalación eléctrica<br/>
                    -	Instalación de alcantarillado/agua potable<br/>
                    -	Entre otros <br/>
                    </h2>
                </div>
            </div>
        </section>
    )
}