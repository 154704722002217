import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

//
import {Header} from "../comp/01-Header";
import {Navbar} from "../comp/02-Navbar";
import {Services} from "../comp/03-Services";
import {MontajeIndustrial} from "../comp/04-MontajeIndustrial"
import {MantenimientoMecanico} from "../comp/05-MantenimientoMecanico";
import {SoldaduraEspecial} from "../comp/06-SoldaduraEspecial";
import {ObrasCiviles} from "../comp/07-ObrasCiviles"
import {Termoplastico} from "../comp/08-Termoplastico"
import ServiciosGenerales from '../comp/09-ServiciosGenerales';
import AseoIndustrial from '../comp/10-AseoIndustrial';
import { Footer } from '../comp/11-Footer';

export function Main (){
    /*********************| Pagina Principal |*********************/
    return (
        <div className='text-white' >
            <Header />
            {/* <Navbar /> */}
            <Services />
            <FloatingWhatsApp  
            phoneNumber="+56988190843" 
            darkMode="true"
            chatMessage="Hola Buenos dias, En que podemos ayudarte?"
            accountName="EMIZA SPA"
            />
            <MontajeIndustrial />
            <MantenimientoMecanico />
            <SoldaduraEspecial />
            <ObrasCiviles />
            <Termoplastico />
            <ServiciosGenerales />
            <AseoIndustrial/>
                {/******************|FOOTER|*****************************/}
            <Footer/>
        </div>
    )
}