import React from 'react'
import background from "../assets/aseo-industrial.jpg";


export default function AseoIndustrial() {
  return (
    <section className='section w-full' id='aseoindustrial' style={{ backgroundImage: `url(${background})` }}>
            <div className='container mx-auto mt-16 mb-14'>
                <div className=''>
                    <h1 className='font-extrabold text-5xl lg:text-7xl mb-5'>Aseo <br/>Industrial  <br/></h1>
                    <h2 className='bg-black p-5'>
                    El área de Aseo industrial se encarga de proveer a nuestros clientes servicios de alta calidad en estándares de limpieza, aseo y mantención en las industrias, a través de nuestros profesionales que se encuentran calificado y poseen la experiencia necesaria para desarrollar las operaciones.<br/><br/>
                    •	Ductos de Aguamar<br/>
                    •	Precalentadores<br/>
                    •	Tornillos de fondos<br/>
                    •	Calderas<br/>
                    •	Silos<br/>
                    •	Oficinas y complejos <br/>
                    •	Edificio y condominios <br/>
                    •	Limpieza de vidrios en altura<br/>
                    •	Entre otros <br/>
                </h2>
                </div>
            </div>
        </section>
  )
}
