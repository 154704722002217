import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Header } from '../comp/01-Header';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Footer } from '../comp/11-Footer';



export default function Contacto () {

  const [nombref, setNombref] = useState();
  const [correof, setCorreof] = useState();
  const [mf, setMf] = useState();

    const form = useRef();


  const clear = () => {
    document.getElementById("nform").value = "";
    setNombref(null);
    document.getElementById("eform").value = "";
    setCorreof(null);
    document.getElementById("mform").value = "";
    setMf(null);
  }

    const sendEmail = (e) => {
      e.preventDefault();
  
      

      if(nombref === null || nombref === "" || nombref === undefined || correof === "" || correof === null || correof === undefined || mf === "" || mf === null || mf === undefined){
        alert ("Faltan datos, revise todos los campos")
      }else{
      emailjs
        .sendForm('service_9fdz8sd', 'template_5hx9zxb', form.current, {
          publicKey: 'iSP2bwfIn7KllnRRy',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert("Mensaje Enviado");
            clear();
          },
          (error) => {
            console.log('FAILED...', error.text);
            alert(error.text)
          },
        );
        }}

    return (
        <div>
            <Header/>
            <FloatingWhatsApp  
            phoneNumber="+56988190843" 
            darkMode="true"
            chatMessage="Hola Buenos dias, En que podemos ayudarte?"
            accountName="EMIZA SPA"
            />
        <section className='lg:section bg-site bg-cover'>
        
            <div className='container mx-auto'>
                <div className='flex flex-col lg:flex-row gap-x-10 mb-10'>
                {/********* */}
                <div className='flex-1 flex justify-start items-center'>
                    <div>
                        <h4 className='mt-2 text-[36px] lg:text-[30px] font-Alkatra text-white uppercase leading-[1] '>
                            Contáctanos
                        </h4>
                        <h2 className='text-[55px] font-bold leading-[0.8] lg:text-[100px] text-white uppercase mb-5'>
                        <span className='' >Trabajemos</span> juntos
                        </h2>
                    </div>
                </div>
                </div>

            {/********/}
            <form className='flex-1 border rounded-2xl flex flex-col gap-y-6 -mt-10 p-6 items-start' ref={form} onSubmit={sendEmail}>
                <label className='block text-[26px] lg:text-[20px] font-Alkatra uppercase leading-[1] text-black font-bold'>Name</label>
                <input placeholder='Ingresa tu nombre' 
                      className='bg-transparent border-b py-3 outline-none w-full text-white placeholder:text-white focus:text-white transition-all' 
                      type="text" id="nform"
                      name="from_name" onChange={(event) => setNombref(event.target.value)}/>

                <label className='block text-[26px] lg:text-[20px] font-Alkatra uppercase leading-[1] text-black font-bold'>Email</label>
                <input placeholder='Ingresa un correo' 
                      className='bg-transparent border-b py-3 outline-none w-full text-white placeholder:text-white focus:text-white transition-all' 
                      type="email"  id="eform"
                      name="from_email" onChange={(event) => setCorreof(event.target.value)}/>

                <label  className="block text-[26px] lg:text-[20px] font-Alkatra uppercase leading-[1] text-black font-bold ">Message</label>
                <textarea placeholder='escribe tu consulta' 
                          className='bg-transparent border-b outline-none w-full text-white  placeholder:text-white focus:text-white transition-all resize-none' 
                          name="message" id="mform" onChange={(event) => setMf(event.target.value)}/>

                <input type="submit" className='btn btn-lg' value="Send" />
            </form>

            </div>
        </section>
        <Footer/>
        </div>
    )
}