import React from 'react'
import {Header} from '../comp/01-Header'

export default function TrabajaConNosotros() {
  return (
    <div>
        <Header />
    </div>
  )
}
