import React from 'react'
import background from "../assets/site-bg.jpg";
//
import img1 from '../assets/img/1.png'
import img2 from '../assets/img/2.png'
import img3 from '../assets/img/3.png'
import img4 from '../assets/img/4.png'
import img5 from '../assets/img/5.png'
import img6 from '../assets/img/6.jpg'
import img7 from '../assets/img/7.jpg'

export function Services  () {
    return (
        <section className='min-h-[65vh] lg:min-h-[85vh] flex text-black  items-center w-full bg-cover' id='services' style={{ backgroundImage: `url(${background})` }} >
            {/* ******************************************** */}
            <div className='hidden md:flex container mx-auto lg:grid lg:grid-cols-7 lg:gap-5  text-center bg-cover bg-center'> 
                <div className='font-light'>
                    <img src={img1} alt='...' className='rounded-full'/>
                    <span className='font-extrabold'>MONTAJE <br/></span> INDUSTRIAL
                </div>

                <div className='font-light'>
                    <img src={img2} alt='...' className='rounded-full'/>
                    <span className='font-extrabold'>MANTENIMIENTO <br/></span>MECANICO
                </div>
                
                <div className='font-light'>
                    <img src={img3} alt='...' className='rounded-full'/>
                    <span  className='font-extrabold'>SOLDADURA <br/></span> ESPECIAL Y GENERAL
                </div>
                
                <div className='font-light'>
                    <img src={img4} alt='...' className='rounded-full'/>
                    <span  className='font-extrabold'>OBRAS<br/> </span> CIVILES
                </div>
                
                <div className='font-light'>
                    <img src={img5} alt='...' className='rounded-full'/>
                    <span  className='font-extrabold'>TERMOPLASTICO<br/></span>
                </div>

                <div className='font-light'>
                    <img src={img6} alt='...' className='rounded-full h-[140px] w-[140px] ml-3'/>
                    <span  className='font-extrabold'>SERVICIOS<br/> </span> GENERALES
                </div>
                
                <div className='font-light'>
                    <img src={img7} alt='...' className='rounded-full h-[140px] w-[140px] ml-3'/>
                    <span  className='font-extrabold'>ASEO <br/></span>INDUSTRIAL
                </div>
            </div>

{/* **************************** */}
            <div className='md:hidden ml-24 my-10 grid grid-cols-1 gap-5  text-center bg-cover bg-center'> 
                <div className='font-light'>
                    <img src={img1} alt='...' className='rounded-full h-[200px] ml-3'/>
                    <span className='font-extrabold'>MONTAJE <br/></span> INDUSTRIAL
                </div>

                <div className='font-light'>
                    <img src={img2} alt='...' className='rounded-full h-[200px] ml-3' />
                    <span className='font-extrabold'>MANTENIMIENTO <br/></span>MECANICO
                </div>
                
                <div className='font-light'>
                    <img src={img3} alt='...' className='rounded-full h-[200px] ml-3'/>
                    <span  className='font-extrabold'>SOLDADURA <br/></span> ESPECIAL Y GENERAL
                </div>
                
                <div className='font-light'>
                    <img src={img4} alt='...' className='rounded-full h-[200px] ml-3'/>
                    <span  className='font-extrabold'>OBRAS CIVILES</span>
                </div>
                
                <div className='font-light'>
                    <img src={img5} alt='...' className='rounded-full h-[200px] ml-3'/>
                    <span  className='font-extrabold'>TERMOPLASTICO<br/></span>
                </div>

                <div className='font-light'>
                    <img src={img6} alt='...' className='rounded-full h-[200px] w-[200px] ml-3'/>
                    <span  className='font-extrabold'>SERVICIOS GENERALES</span>
                </div>
                
                <div className='font-light'>
                    <img src={img7} alt='...' className='rounded-full h-[200px] w-[200px] ml-3'/>
                    <span  className='font-extrabold'>ASEO INDUSTRIAL<br/></span>
                </div>
            </div>
        </section>
    )
}