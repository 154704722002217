import React from 'react'

import icon1 from '../assets/img/instagram-icon.svg'
import icon2 from '../assets/img/facebook.svg'
import { Link } from 'react-router-dom'


export function Footer() {
    return (
        <div className='pt-10 bg-white text-black lg:p-10'>
                <div className='ml-[10%] lg:ml-[14%] xl:ml-[20%]'>  
{/*********|FOOTER|************************************************************************************ */} 
                <div className=''>
                    <div className='pr-4 lg:flex  lg:pr-18 pb-10'>
                        <div className='lg:w-[430px] '>
                            <h1 className='text-[30px] -ml-8 mb-4 lg:text-[50px] font-bold text-black leading-tight text-center lg:pt-10'> 
                                MANTENTE CONECTADO
                            </h1>
                            De Lunes a Viernes, 9:00 am a 7:00 pm los Sábados de 10:00 am - 2:00 pm. Síguenos en redes sociales y mantente al día con toda la información que necesites saber sobre nosotros.<br/>
                        </div>
                
                        <div className=' text-center lg:grid lg:grid-cols-2 pl-12 pt-10 lg:pl-20 lg:gap-10'>
                    {/********* */}
                            <div className='pb-5 '>
                                <div className='lg:grid lg:grid-cols-1 text-black pt-4'>
                                    <Link to="https://www.instagram.com/emiza_servicios_integrales_spa/?igsh=YXpyNGtsNmh5NzY1&utm_source=qr">
                                        <div className='flex text-black'>
                                            <div className='pr-2 pb-3'>
                                                <img src={icon1} className='w-[25px]'/>
                                            </div>
                                            <div className='-mt-1'>
                                                INSTAGRAM
                                            </div >
                                        </div>
                                    </Link>
                                <Link to="https://web.facebook.com/people/Emiza-servicios-integrales-SpA/61558388309129/?mibextid=WC7FNe&rdid=8t2uQEWADKWvRpy4">
                                    <div className='flex text-black'>
                                        <div className='pr-2'>
                                            <img src={icon2} className='w-[25px]'/>
                                        </div>
                                        <div className='-mt-1'>
                                            FACEBOOK
                                        </div >
                                    </div>
                                </Link>

                                </div>
                            </div>

                    {/********* */}
                            <div className='pb-5'>
                                <div className='text-xl font-bold text-left' >BARBARA REYES</div>
                                    <div className='flex'>
                                        <div className='pr-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                            </svg>
                                        </div>
                                        <div className=''>
                                            +56 9 8512 8218
                                        </div >
                                    </div>

                                    <div className='flex'>
                                        <div className='pr-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                            </svg>
                                        </div>
                                        <div className='-mt-1'>
                                        Prevencion@emiza.cl
                                        </div>
                                    </div>
                                </div>

                    {/********* */}
                            <div className='pb-5'>
                                <div className='text-xl font-bold text-left' >FRANCISCO IZAURRAULDES</div>
                                <div className='flex'>
                                    <div className='pr-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>
                                    </div>
                                    <div className=''>
                                        +56 9 5323 8304
                                    </div >
                                </div>
                                <div className='flex'>
                                    <div className='pr-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </div>
                                    <div className='-mt-1'>
                                        Gerencia@emiza.cl
                                    </div>
                                </div>
                            </div>

                    {/********* */}
                            <div className='pb-5'>
                                <div className='text-xl font-bold text-left' >ADMINISTRACION</div>
                                <div className='flex'>
                                    <div className='pr-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>
                                    </div>
                                    <div className=''>
                                    +56 9 8819 0843
                                    </div >
                                </div>
                                <div className='flex'>
                                    <div className='pr-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </div>
                                    <div className='-mt-1'>
                                        Administracion@emiza.cl
                                    </div>
                                </div>
                            </div>

                            </div>
                        </div> 
                </div>
            </div>
        </div>
    )
}